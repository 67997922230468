<template>
  <v-card>
    <v-card-title>入札実績(履歴)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <template>
        <v-spacer></v-spacer>
        <v-form class="ma-6">
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="bid_id">入札ID</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <label for="bid_id">{{ biddingProgress.bid_id }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="auc_id">商品ID</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <a for="auc_id" href="javascript:void(0);" style="text-decoration: underline;" @click="openOverviewDialog">{{ biddingProgress.auc_code }} ( {{ biddingProgress.auc_id }} )</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="customer_id">取引先</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <label
                    for="reserve_id"
                    v-bind="attrs"
                    v-on="on"
                  >{{ biddingProgress.cust_abbr }}({{ biddingProgress.cust_id }})</label>
                </template>
                <span>{{ biddingProgress.cust_name }}</span><br>
                <span>{{ biddingProgress.cust_name_eng }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="bid_dt">入札日時</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <label>{{ biddingProgress.bid_dt }}</label>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="bid_weight">総重量</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <label for="bid_weight">
                {{ formatData(biddingProgress.bid_weight) }}KG
              </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="bid_amount">総金額</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <label for="bid_amount">{{ biddingProgress.ccy_mark }}{{ formatPrice(biddingProgress.bid_amount) }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="bid_status">現在状態</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <label for="bid_status">{{ biddingProgress.bid_stat_desc }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                dense
                :headers="headers"
                :items="ProgressDetail"
                item-key="no"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.bid_stat_desc`]="{ item }">
                  <div>
                    {{ item.bid_stat_desc }} {{ getProcFrom(item.proc_from) }}
                  </div>
                </template>
                <template v-slot:[`item.proc_memo_cust`]="{ item }">
                  <div>
                    {{ item.proc_memo_cust }}
                  </div>
                </template>
                <template v-slot:[`item.proc_memo_admin`]="{ item }">
                  <div>
                    {{ item.proc_memo_admin }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="reserved_note">備考</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-textarea
                v-model="bid_note"
                name="bid_note"
                outlined
                dense
                placeholder="変更内容など記入"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="submit()"
              >
                Submit
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <!-- <ReservedPreview
          :id="biddingProgress.po_id"
          :resv-dialog="resvDialog"
          @closeForm="closeReservedPreivew"
        ></ReservedPreview> -->
      </template>
    </template>
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiCalendar,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

// import BiddingPreview from './ReservedPreview.vue'

export default {
  components: {

  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiCalendar,
    },
    resvDialog: false,

    submitStatus: false,
    ProgressDetail: [],
    changeFlag: false,

    apierror: [],
    bid_stat: '',
    bid_note: '',
    bidStatuses: [
      {
        text: '落札', value: 9,
      },
      {
        text: '失注', value: 2,
      },
    ],
    dialog: false,
    headers: [
      {
        text: 'No.',
        align: 'left',
        sortable: false,
        width: '5%',
        value: 'no',
      },
      {
        text: '処理日時',
        align: 'left',
        sortable: false,
        width: '19%',
        value: 'proc_dt',
      },
      {
        text: '操作詳細',
        align: 'left',
        sortable: false,
        width: '12%',
        value: 'bid_stat_desc',
      },
      {
        text: '価格',
        align: 'left',
        sortable: false,
        width: '8%',
        value: 'bid_amount',
      },

      {
        text: '備考(取引先)',
        align: 'left',
        sortable: false,
        width: '28%',
        value: 'proc_memo_cust',
      },
      {
        text: '備考(GE)',
        align: 'left',
        sortable: false,
        width: '28%',
        value: 'proc_memo_admin',
      },
    ],
  }),
  computed: {
    ...mapState('productStore', ['productMaster', 'productIds']),
    ...mapState('bidStore', ['bidStatusMaster', 'biddingProgress']),

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    formatPrice() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return parseFloat(data).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    getProcFrom() {
      return function (data) {
        if (data === 1) {
          return '(取引先)'
        }
        if (data === 2) {
          return '(GE)'
        }

        return ''
      }
    },
  },
  watch: {
    bid_note(val) {
      this.changeFlag = true
      if (val === '') {
        this.changeFlag = false
      }
    },
    bid_stat(val) {
      this.changeFlag = true
      if (val === '') {
        this.changeFlag = false
      }
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('productStore', ['loadProductIds']),
    ...mapActions('bidStore', ['editBid', 'loadBiddingStatus', 'loadProgress']),
    ...mapMutations('app', ['setOverlayStatus']),
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }
      }
      this.jumpToListPage()
    },

    jumpToListPage() {
      console.log('cancel', this.$route.params)

      if (this.$route.params.from === 1) {
        this.$router.push({
          path: '/bidding-list',
          query: this.$route.params.query ?? {
          },
        })

        return
      }

      if (this.$route.params.from === 9) {
        this.$router.push({
          path: '/quotation-list',
          query: this.$route.params.query ?? {
          },
        })

        return
      }

      this.$router.push({
        path: '/biddingwon-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    closeReservedPreivew() {
      this.resvDialog = false
    },

    openOverviewDialog() {
      this.resvDialog = true
    },

    // getResvStatuses() {
    // console.log('resvList:', this.resvStatusList)
    // this.resvStatuses = this.resvStatusList.map(v => {
    //   const obj = {
    //   }
    //   obj.text = `${v.bid_stat}: ${v.bid_stat_desc}`
    //   obj.value = `${v.bid_stat}`

    //   return obj
    // })
    // },

    loadData() {
      console.log('param:', this.$route.params.id, this.$route.params.from)
      this.setOverlayStatus(true)
      Promise.all([
        // this.loadReservationStatus(),
        this.loadProgress(this.$route.params.id),
      ]).then(() => {
        // this.getResvStatuses()
        console.log('biddingProgress', this.biddingProgress)
        this.ProgressDetail = this.biddingProgress.details.map(r => {
          const obj = r
          if (r.proc_from === 1) {
            obj.proc_memo_cust = r.proc_memo
          } else {
            obj.proc_memo_admin = r.proc_memo
          }

          return obj
        })
      })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        }).finally(() => {
          this.setOverlayStatus(false)
        })
    },

    submit() {
      this.apierror.messages = []
      this.submitStatus = true
      const submitForm = {
        bid_note: this.bid_note,
        bid_stat: this.bid_stat,
        bid_id: this.biddingProgress.bid_id,
      }

      console.log('submit:', submitForm)
      this.editBid(submitForm)
        .then(() => {
          this.submitStatus = false
          this.jumpToListPage()
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.submitStatus = false
        })
    },
  },
}
</script>
